<template>
  <div class="nlp">
    <div class="toolbar">
      <el-form class="search-form" :inline="true" size="small">
        <el-form-item
          class="search-form-gutter"
          :label="$t('nlpAnalysis.businessType')"
        >
          <el-select
            style="width: 66px"
            v-model="listQuery.businessType"
            filterable
          >
            <el-option
              v-for="item in businessTypeList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="search-form-gutter"
          :label="$t('nlpAnalysis.date')"
        >
          <el-select
            style="width: 175px"
            v-model="listQuery.dates"
            filterable
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in dateList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="search-form-gutter"
          :label="$t('nlpAnalysis.compareDate')"
        >
          <el-select
            style="width: 175px"
            v-model="listQuery.compareDates"
            filterable
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in dateList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="search-form-gutter"
          :label="$t('nlpAnalysis.item')"
        >
          <el-select
            style="width: 221px"
            v-model="listQuery.items"
            :placeholder="$t('common.all')"
            filterable
            multiple
            collapse-tags
            clearable
          >
            <el-option
              v-for="item in itemList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="search-form-gutter"
          :label="$t('nlpAnalysis.length')"
        >
          <el-select
            style="width: 157px"
            v-model="listQuery.lengthTypes"
            @change="changeDate"
            :placeholder="$t('common.all')"
            filterable
            multiple
            collapse-tags
            clearable
          >
            <el-option
              v-for="item in lengthList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="search-form-gutter"
          :label="$t('nlpAnalysis.category')"
        >
          <el-cascader
            style="width: 342px"
            v-model="listQuery.categories"
            :options="categoriesList"
            :props="{ multiple: true, label: 'value' }"
            :placeholder="$t('common.all')"
            filterable
            collapse-tags
            clearable
          >
          </el-cascader>
        </el-form-item>
        <el-form-item class="search-form-gutter">
          <el-button
            :disabled="!listQuery.dates || listQuery.dates.length === 0"
            size="small"
            :circle="true"
            type="primary"
            @click="fetchData"
            icon="el-icon-search"
          ></el-button>
        </el-form-item>
        <el-form-item class="search-form-gutter">
          <el-button
            :disabled="!listQuery.dates || listQuery.dates.length === 0"
            class="excel-dl-btn"
            size="small"
            :circle="true"
            @click="handleDownload"
            icon="el-icon-download"
          ></el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-row class="data-area" v-loading="dataLoading">
      <el-col :span="12">
        <div class="date-chart" id="compare-bar-chart" />
      </el-col>
      <el-col :span="12">
        <div class="date-chart" id="compare-diff-bar-chart" />
      </el-col>
      <el-col :span="12">
        <div class="date-chart" id="date-chart" />
      </el-col>
      <el-col :span="12">
        <div class="date-chart" id="compare-date-chart" />
      </el-col>
    </el-row>
  </div>
</template>
  
<script>
import * as echarts from "echarts";
import { downloadFileAuth, serialize } from "@/utils/download-file";
import { aiApiRoot } from "@/utils/server";
import { getToken } from "@/utils/auth";
import {
  fetchBusinessTypes,
  fetchCategories,
  fetchDates,
  fetchWordFrequency,
} from "@/api/nlp";

export default {
  components: {},
  data() {
    return {
      listQuery: {
        businessType: "",
        dates: [],
        compareDates: [],
        items: [],
        lengthTypes: [],
        categories: [],
      },
      tableData: [],
      compareTableData: [],
      businessTypeList: [],
      dateList: [],
      itemList: [],
      lengthList: ["1.1", "2.02~04", "3.05~07", "4.08~"],
      categoriesList: [],
      dataLoading: false,
    };
  },
  mounted() {
    this.comparePieChart = echarts.init(document.getElementById("date-chart"));
    this.compareComparePieChart = echarts.init(
      document.getElementById("compare-date-chart")
    );
    this.compareBarChart = echarts.init(
      document.getElementById("compare-bar-chart")
    );
    this.compareDiffBarChart = echarts.init(
      document.getElementById("compare-diff-bar-chart")
    );
    window.addEventListener("resize", this.loadChart);
    this.fetchBusinessTypeList();
    this.fetchDates();
    this.fetchCategories();
    this.$store.dispatch("category/fresh").then(() => {
      this.itemList = this.$store.getters.t2AdditionalFlatten;
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.loadChart);
    this.unloadChart();
  },
  methods: {
    loadChart() {
      this.comparePieChart.resize();
      this.compareComparePieChart.resize();
      this.compareBarChart.resize();
      this.compareDiffBarChart.resize();
    },
    unloadChart() {
      this.comparePieChart.dispose();
      this.compareComparePieChart.dispose();
      this.compareBarChart.dispose();
      this.compareDiffBarChart.dispose();
    },
    fetchBusinessTypeList() {
      fetchBusinessTypes().then((response) => {
        if (response.success) {
          this.businessTypeList = response.result.items || [];
          if (this.businessTypeList.length > 0) {
            this.listQuery.businessType = this.businessTypeList[0];
            this.fetchData();
          }
        }
      });
    },
    fetchCategories() {
      fetchCategories(this.source).then((response) => {
        if (response.success) {
          this.categoriesList = response.result;
        }
      });
    },
    fetchDates() {
      fetchDates(this.source).then((response) => {
        if (response.success) {
          this.dateList = response.result.items || [];
          if (this.dateList.length > 1) {
            this.listQuery.dates = [this.dateList[0]];
            this.listQuery.compareDates = [this.dateList[1]];
            this.fetchData();
          }
        }
      });
    },
    fetchData() {
      if (
        !this.listQuery.dates ||
        this.listQuery.dates.length === 0 ||
        !this.listQuery.businessType
      ) {
        return;
      }
      this.dataLoading = true;
      let morphemes = [];
      this.tableData = [];
      this.compareTableData = [];
      // 将categories拆解为Category3，不需要其他
      this.listQuery.categories.forEach((e) => {
        morphemes.push(e[e.length - 1]);
      });
      fetchWordFrequency(
        {
          dates: this.listQuery.dates.join(","),
          items: this.listQuery.items.join(","),
          lengthTypes: this.listQuery.lengthTypes.join(","),
          morphemes: morphemes.join(","),
          businessType: this.listQuery.businessType,
          limit: 20,
        },
        this.source
      )
        .then((response) => {
          if (response.success) {
            this.tableData = response.result.items || [];
            if (this.source === "SW") {
              this.tableData.forEach((e) => {
                e.num *= 1888;
              });
            }
            this.drawChart(
              this.comparePieChart,
              this.tableData,
              this.$t("nlpAnalysis.date")
            );
            this.drawBarChart();
          } else {
            this.dataLoading = false;
          }
        })
        .catch(() => {
          this.dataLoading = false;
        });
      fetchWordFrequency(
        {
          dates: this.listQuery.compareDates.join(","),
          items: this.listQuery.items.join(","),
          lengthTypes: this.listQuery.lengthTypes.join(","),
          morphemes: morphemes.join(","),
          businessType: this.listQuery.businessType,
          limit: 20,
        },
        this.source
      )
        .then((response) => {
          if (response.success) {
            this.compareTableData = response.result.items || [];
            this.drawChart(
              this.compareComparePieChart,
              this.compareTableData,
              this.$t("nlpAnalysis.compareDate")
            );
            this.drawBarChart();
          } else {
            this.dataLoading = false;
          }
        })
        .catch(() => {
          this.dataLoading = false;
        });
    },
    handleDownload() {
      const loading = this.$loading({
        lock: true,
        text: this.$t("common.preparingData"),
      });
      let morphemes = [];
      // 将categories拆解为Category3，不需要其他
      this.listQuery.categories.forEach((e) => {
        morphemes.push(e[e.length - 1]);
      });
      let query = {
        dates: this.listQuery.dates.join(","),
        compareDates: this.listQuery.compareDates.join(","),
        items: this.listQuery.items.join(","),
        lengthTypes: this.listQuery.lengthTypes.join(","),
        morphemes: morphemes.join(","),
        businessType: this.listQuery.businessType,
        limit: 20,
      };
      downloadFileAuth(
        aiApiRoot +
          "/v1/nlp-analysis/" +
          this.source +
          "/word-frequency-compare-download?" +
          serialize(query),
        query.dates + "_nlp_analysis_" + this.source + ".xlsx",
        getToken(),
        () => {
          loading.close();
        }
      );
    },
    drawChart(chart, data, title) {
      let option = {
        title: {
          text: title,
          subtext: "Best 20",
          padding: [5, 10],
        },
        tooltip: {},
        legend: {
          type: "scroll",
          orient: "vertical",
          top: "center",
          right: 10,
          data: [],
          reversed: true,
          selector: [
            {
              type: "all",
              title: this.$t("common.selectAll"),
            },
            {
              type: "inverse",
              title: this.$t("common.selectReverse"),
            },
          ],
        },
        series: [
          {
            name: "Word",
            type: "pie",
            radius: "55%",
            center: ["40%", "50%"],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            data: [],
          },
        ],
      };
      data.forEach((e, i) => {
        if (i < 20) {
          option.series[0].data.push({
            name: e.keyword,
            value: e.num,
          });
          option.legend.data.push(e.keyword);
        }
      });
      chart.setOption(option, true);
    },
    drawBarChart() {
      if (!this.tableData || !this.compareTableData) {
        return;
      }
      this.dataLoading = false;
      let option = {
        title: {
          text: this.$t("nlpAnalysis.compare"),
          padding: [5, 10],
        },
        grid: {
          left: 20,
          right: 20,
          bottom: 5,
          containLabel: true,
        },
        legend: {
          data: [
            this.$t("nlpAnalysis.date"),
            this.$t("nlpAnalysis.compareDate"),
          ],
        },
        tooltip: {
          valueFormatter: (value) => value + " %",
        },
        xAxis: {
          type: "category",
          axisLabel: {
            rotate: 90,
            fontSize: 10,
          },
          data: [],
        },
        yAxis: {
          axisLabel: {
            formatter: "{value} %",
          },
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          { name: this.$t("nlpAnalysis.date"), type: "bar", data: [] },
          { name: this.$t("nlpAnalysis.compareDate"), type: "bar", data: [] },
        ],
      };
      let diffOption = {
        title: {
          text: this.$t("nlpAnalysis.compare"),
          subtext:
            this.$t("nlpAnalysis.date") +
            " - " +
            this.$t("nlpAnalysis.compareDate"),
          padding: [5, 10],
        },
        legend: {
          data: ["Diff"],
        },
        tooltip: {
          valueFormatter: (value) => value + " %",
        },
        grid: {
          left: 20,
          right: 20,
          bottom: 5,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          axisLabel: {
            rotate: 90,
            fontSize: 10,
          },
          data: [],
        },
        yAxis: {
          axisLabel: {
            formatter: "{value} %",
          },
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [{ name: "Diff", type: "bar", data: [] }],
      };
      let data = this.percetageData(this.tableData);
      let compareData = this.percetageData(this.compareTableData);
      let summary = [];
      data.forEach((e) => {
        let exist = false;
        for (let i = 0; i < summary.length; i++) {
          if (summary[i].name === e.keyword) {
            summary[i].data[0] = e.percentage;
            exist = true;
          }
        }
        if (!exist) {
          summary.push({ name: e.keyword, data: [e.percentage, 0] });
        }
      });
      compareData.forEach((e) => {
        let exist = false;
        for (let i = 0; i < summary.length; i++) {
          if (summary[i].name === e.keyword) {
            summary[i].data[1] = e.percentage;
            exist = true;
          }
        }
        if (!exist) {
          summary.push({ name: e.keyword, data: [0, e.percentage] });
        }
      });
      summary.forEach((e) => {
        option.series[0].data.push(Math.round(e.data[0]) / 100);
        option.series[1].data.push(Math.round(e.data[1]) / 100);
        option.xAxis.data.push(e.name);

        if (e.data[0] > 0 && e.data[1] > 0) {
          diffOption.series[0].data.push(
            Math.round(e.data[0] - e.data[1]) / 100
          );
          diffOption.xAxis.data.push(e.name);
        }
      });
      this.compareBarChart.setOption(option, true);
      this.compareDiffBarChart.setOption(diffOption, true);
    },
    percetageData(data) {
      let items = [];
      let total = 0,
        remain = 0;
      for (let i = 0; i < 20 && i < data.length; i++) {
        const e = data[i];
        total += e.num;
        items.push({ keyword: e.keyword, percentage: 0 });
      }
      items.forEach((_, i) => {
        items[i].percentage = (data[i].num / total) * 10000;
        remain += items[i].percentage;
      });
      // 补齐误差
      if (items.length > 0) {
        items[0].percentage += 10000 - remain;
      }
      return items;
    },
    changeDate() {},
  },
  computed: {
    source() {
      if (!this.$route.meta) {
        return null;
      }
      return this.$route.meta["source"];
    },
  },
};
</script>
  
<style lang="scss" scoped>
.nlp {
  .toolbar {
    background-color: #fff;
    padding: 10px 10px 0 10px;
    width: 100%;
    margin-bottom: 9.5px;

    .search-form {
      min-height: 32px;
      display: inline-block;

      .search-form-gutter {
        margin-bottom: 10px;
      }

      /deep/ .el-cascader .el-input__inner {
        height: 32px !important;
      }

      /deep/ .el-cascader__tags {
        padding-top: 2px;
      }
    }
  }

  .data-area {
    background-color: #fff;
    margin-top: 5px;
    padding-top: 10px;

    .date-chart {
      width: 100%;
      min-height: 323px;
      margin-bottom: 10px;
    }
  }
}
</style>