import request from '@/utils/request'

export function fetchBusinessTypes() {
  return request({
    url: '/v1/nlp-analysis/business-types',
    method: 'get',
  })
}

export function fetchCategories(source) {
  return request({
    url: '/v1/nlp-analysis/' + source + '/categories',
    method: 'get',
  })
}

export function fetchDates(source) {
  return request({
    url: '/v1/nlp-analysis/' + source + '/dates',
    method: 'get',
  })
}

export function fetchWordFrequency(query, source) {
  return request({
    url: '/v1/nlp-analysis/' + source + '/word-frequency',
    method: 'get',
    params: query
  })
}

export function fetchWordFrequencyTrend(query, source) {
  return request({
    url: '/v1/nlp-analysis/' + source + '/word-frequency-trend',
    method: 'get',
    params: query
  })
}