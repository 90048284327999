<template>
  <div class="nlp">
    <div class="toolbar">
      <el-form class="search-form" :inline="true" size="small">
        <el-form-item
          class="search-form-gutter"
          :label="$t('nlpAnalysis.businessType')"
        >
          <el-select
            style="width: 66px"
            v-model="listQuery.businessType"
            filterable
          >
            <el-option
              v-for="item in businessTypeList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="search-form-gutter"
          :label="$t('nlpAnalysis.date')"
        >
          <el-select
            style="width: 175px"
            v-model="listQuery.dates"
            filterable
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in dateList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="search-form-gutter"
          :label="$t('nlpAnalysis.item')"
        >
          <el-select
            style="width: 221px"
            v-model="listQuery.items"
            :placeholder="$t('common.all')"
            filterable
            multiple
            collapse-tags
            clearable
          >
            <el-option
              v-for="item in itemList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="search-form-gutter"
          :label="$t('nlpAnalysis.length')"
        >
          <el-select
            style="width: 157px"
            v-model="listQuery.lengthTypes"
            @change="changeDate"
            :placeholder="$t('common.all')"
            filterable
            multiple
            collapse-tags
            clearable
          >
            <el-option
              v-for="item in lengthList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="search-form-gutter"
          :label="$t('nlpAnalysis.category')"
        >
          <el-cascader
            style="width: 342px"
            v-model="listQuery.categories"
            :options="categoriesList"
            :props="{ multiple: true, label: 'value' }"
            :placeholder="$t('common.all')"
            filterable
            collapse-tags
            clearable
          >
          </el-cascader>
        </el-form-item>
        <el-form-item class="search-form-gutter">
          <el-button
            :disabled="!listQuery.dates || listQuery.dates.length === 0"
            size="small"
            :circle="true"
            type="primary"
            @click="fetchData"
            icon="el-icon-search"
          ></el-button>
        </el-form-item>
        <el-form-item class="search-form-gutter">
          <el-button
            :disabled="!listQuery.dates || listQuery.dates.length === 0"
            class="excel-dl-btn"
            size="small"
            :circle="true"
            @click="handleDownload"
            icon="el-icon-download"
          ></el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-row class="data-area" v-loading="dataLoading">
      <el-col :span="11">
        <div id="word-frequency-chart" />
      </el-col>
      <el-col :span="13">
        <el-row class="word-cloud-container">
          <vue-word-cloud
            class="word-cloud"
            :words="randomWords"
            :color="wordColor"
            font-family="Roboto"
            :font-size-ratio="10"
          >
            <template slot-scope="{ text, weight, word }">
              <div
                :title="weight"
                style="cursor: pointer"
                @click="onWordClick(word)"
              >
                {{ text }}
              </div>
            </template>
          </vue-word-cloud>
        </el-row>
        <el-row class="top-text">
          <el-col :span="6" v-for="(item, index) in topText" :key="index">
            <div class="top-text-area">
              <span class="top-text-title">
                {{ padding10(index) + " " + item.keyword }}
              </span>
              <span class="top-text-percentage">
                {{ Math.round(item.percentage) / 100 + "%" }}
              </span>
              <div style="clear: both"></div>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
  
<script>
import * as echarts from "echarts";
import VueWordCloud from "vuewordcloud";
import { downloadFileAuth, serialize } from "@/utils/download-file";
import { aiApiRoot } from "@/utils/server";
import { getToken } from "@/utils/auth";
import {
  fetchBusinessTypes,
  fetchCategories,
  fetchDates,
  fetchWordFrequency,
} from "@/api/nlp";

export default {
  components: {
    VueWordCloud,
  },
  data() {
    return {
      listQuery: {
        businessType: "",
        dates: [],
        items: [],
        lengthTypes: [],
        categories: [],
      },
      tableData: [],
      wordColors: [
        { index: 0, color: "#546570" },
        { index: 0, color: "#6e7074" },
        { index: 0, color: "#bda29a" },
        { index: 0, color: "#ca8622" },
        { index: 0, color: "#749f83" },
        { index: 0, color: "#91c7ae" },
        { index: 0, color: "#d48265" },
        { index: 0, color: "#61a0a8" },
        { index: 0, color: "#2f4554" },
        { index: 0, color: "#c23531" },
      ],
      businessTypeList: [],
      dateList: [],
      itemList: [],
      lengthList: ["1.1", "2.02~04", "3.05~07", "4.08~"],
      categoriesList: [],
      dataLoading: false,
      randomWords: [],
    };
  },
  mounted() {
    this.chart = echarts.init(document.getElementById("word-frequency-chart"));
    window.addEventListener("resize", this.loadChart);
    this.fetchBusinessTypeList();
    this.fetchDates();
    this.fetchCategories();
    this.$store.dispatch("category/fresh").then(() => {
      this.itemList = this.$store.getters.t2AdditionalFlatten;
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.loadChart);
    this.unloadChart();
  },
  methods: {
    loadChart() {
      this.chart.resize();
    },
    unloadChart() {
      this.chart.dispose();
    },
    fetchBusinessTypeList() {
      fetchBusinessTypes().then((response) => {
        if (response.success) {
          this.businessTypeList = response.result.items || [];
          if (this.businessTypeList.length > 0) {
            this.listQuery.businessType = this.businessTypeList[0];
            this.fetchData();
          }
        }
      });
    },
    fetchCategories() {
      fetchCategories(this.source).then((response) => {
        if (response.success) {
          this.categoriesList = response.result;
        }
      });
    },
    fetchDates() {
      fetchDates(this.source).then((response) => {
        if (response.success) {
          this.dateList = response.result.items || [];
          if (this.dateList.length > 0) {
            this.listQuery.dates = [this.dateList[0]];
            this.fetchData();
          }
        }
      });
    },
    fetchData() {
      if (
        !this.listQuery.dates ||
        this.listQuery.dates.length === 0 ||
        !this.listQuery.businessType
      ) {
        return;
      }
      this.dataLoading = true;
      let morphemes = [];
      // 将categories拆解为Category3，不需要其他
      this.listQuery.categories.forEach((e) => {
        morphemes.push(e[e.length - 1]);
      });
      fetchWordFrequency(
        {
          dates: this.listQuery.dates.join(","),
          items: this.listQuery.items.join(","),
          lengthTypes: this.listQuery.lengthTypes.join(","),
          morphemes: morphemes.join(","),
          businessType: this.listQuery.businessType,
          limit: 200,
        },
        this.source
      )
        .then((response) => {
          this.dataLoading = false;
          if (response.success) {
            let items = response.result.items || [];
            if (this.source === "SW") {
              items.forEach((e) => {
                e.num *= 1888;
              });
            }
            if (items.length > 0) {
              this.wordColors.forEach((e, i) => {
                let index = Math.floor(
                  // 1 / 5 * 1
                  (i / this.wordColors.length) * items.length
                );
                if (index >= 0) {
                  e.index = index;
                } else {
                  e.index = 0;
                }
              });
            }
            this.tableData = items;
            this.randomWords = items.map((e) => {
              return [e.keyword, e.num];
            });
            for (let i = 1; i < this.randomWords.length; i++) {
              const random = Math.floor(Math.random() * (i + 1));
              [this.randomWords[i], this.randomWords[random]] = [
                this.randomWords[random],
                this.randomWords[i],
              ];
            }
            this.drawChart();
          }
        })
        .catch(() => {
          this.dataLoading = false;
        });
    },
    handleDownload() {
      const loading = this.$loading({
        lock: true,
        text: this.$t("common.preparingData"),
      });
      let morphemes = [];
      // 将categories拆解为Category3，不需要其他
      this.listQuery.categories.forEach((e) => {
        morphemes.push(e[e.length - 1]);
      });
      let query = {
        dates: this.listQuery.dates.join(","),
        items: this.listQuery.items.join(","),
        lengthTypes: this.listQuery.lengthTypes.join(","),
        morphemes: morphemes.join(","),
        businessType: this.listQuery.businessType,
        limit: 20,
      };
      downloadFileAuth(
        aiApiRoot +
          "/v1/nlp-analysis/" +
          this.source +
          "/word-frequency-download?" +
          serialize(query),
        query.dates + "_nlp_analysis_" + this.source + ".xlsx",
        getToken(),
        () => {
          loading.close();
        }
      );
    },
    drawChart() {
      let option = {
        title: {
          text: this.$t("nlpAnalysis.basic"),
          subtext: "Best 20",
          padding: [5, 10],
        },
        tooltip: {
          // trigger: "item",
          // formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          top: "center",
          right: 10,
          data: [],
          reversed: true,
          selector: [
            {
              type: "all",
              title: this.$t("common.selectAll"),
            },
            {
              type: "inverse",
              title: this.$t("common.selectReverse"),
            },
          ],
        },
        series: [
          {
            name: "Word",
            type: "pie",
            radius: "55%",
            center: ["40%", "50%"],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            data: [],
          },
        ],
      };
      this.tableData.forEach((e, i) => {
        if (i < 20) {
          option.series[0].data.push({
            name: e.keyword,
            value: e.num,
          });
          option.legend.data.push(e.keyword);
        }
      });
      this.chart.setOption(option, true);
    },
    changeDate() {},
    wordColor(_, index) {
      for (let i = this.wordColors.length - 1; i >= 0; i--) {
        if (index >= this.wordColors[i].index) {
          return this.wordColors[i].color;
        }
      }
    },
    padding10(num) {
      if (num < 10) {
        return "0" + num;
      } else {
        return "" + num;
      }
    },
  },
  computed: {
    topText() {
      let items = [];
      let total = 0,
        remain = 0;
      for (let i = 0; i < 20 && i < this.tableData.length; i++) {
        const e = this.tableData[i];
        total += e.num;
        items.push({ keyword: e.keyword, percentage: 0 });
      }
      items.forEach((_, i) => {
        items[i].percentage = (this.tableData[i].num / total) * 10000;
        remain += items[i].percentage;
      });
      // 补齐误差
      if (items.length > 0) {
        items[0].percentage += 10000 - remain;
      }
      return items;
    },
    source() {
      if (!this.$route.meta) {
        return null;
      }
      return this.$route.meta["source"];
    },
  },
};
</script>
  
<style lang="scss" scoped>
.nlp {
  .toolbar {
    background-color: #fff;
    padding: 10px 10px 0 10px;
    width: 100%;
    margin-bottom: 9.5px;

    .search-form {
      min-height: 32px;
      display: inline-block;

      .search-form-gutter {
        margin-bottom: 10px;
      }

      /deep/ .el-cascader .el-input__inner {
        height: 32px !important;
      }

      /deep/ .el-cascader__tags {
        padding-top: 2px;
      }
    }
  }

  .data-area {
    background-color: #fff;
    margin-top: 5px;

    .word-cloud-container {
      padding: 25px;

      .word-cloud {
        width: 100%;
        min-height: calc(
          100vh - 100px - 52.5px - 52.5px - 10px - 10px - 80px - 45px - 50px -
            20px
        );
        overflow: hidden;
      }
    }

    .top-text {
      padding-bottom: 20px;
      padding-left: 25px;
      padding-right: 25px;
      font-size: small;
      height: 100px;

      .top-text-area {
        padding-left: 8px;
        padding-right: 8px;
        line-height: 16px;

        .top-text-title {
          color: #a9a9a9;
        }

        .top-text-percentage {
          color: #a9a9a9;
          font-weight: bold;
          float: right;
        }
      }
    }
  }

  #word-frequency-chart {
    width: 100%;
    min-height: 248px;
    height: calc(100vh - 100px - 52.5px - 52.5px - 10px - 10px - 30px - 45px);
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
</style>