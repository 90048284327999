<template>
  <div class="nlp">
    <div>
      <ai-breadcrumb />
      <div class="new-button">
        <guide class="guide-button" />
      </div>
    </div>
    <div class="page-tab">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane :label="$t('nlpAnalysis.basic')" name="basic">
        </el-tab-pane>
        <el-tab-pane :label="$t('nlpAnalysis.compare')" name="compare">
        </el-tab-pane>
        <el-tab-pane :label="$t('nlpAnalysis.trend')" name="trend">
        </el-tab-pane>
      </el-tabs>
    </div>
    <div>
      <analysis-basic v-if="activeName === 'basic'" />
      <analysis-compare v-if="activeName === 'compare'" />
      <analysis-trend v-if="activeName === 'trend'" />
    </div>
  </div>
</template>

<script>
import AiBreadcrumb from "@/components/AiBreadcrumb";
import Guide from "@/components/Guide";
import AnalysisBasic from "@/views/services/nlp/AnalysisBasic";
import AnalysisCompare from "@/views/services/nlp/AnalysisCompare";
import AnalysisTrend from "@/views/services/nlp/AnalysisTrend";

export default {
  components: {
    AiBreadcrumb,
    Guide,
    AnalysisBasic,
    AnalysisCompare,
    AnalysisTrend,
  },
  data() {
    return {
      activeName: "",
    };
  },
  watch: {
    activeName(val) {
      const tab = this.$route.query.tab;
      if (tab !== val) {
        this.$router.push(`${this.$route.path}?tab=${val}`);
      }
    },
  },
  created() {
    // init the default selected tab
    const tab = this.$route.query.tab;
    if (tab === "basic" || tab === "compare" || tab === "trend") {
      this.activeName = tab;
    } else {
      this.activeName = "basic";
    }
  },
};
</script>

<style lang="scss" scoped>
.nlp {
  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;
  }

  .page-tab {
    background-color: white;

    /deep/ .el-tabs__header {
      padding-top: 10px;
      padding-left: 10px;
      margin-bottom: 0;
    }

    /deep/ .el-tabs__item {
      height: 34px;
      line-height: 34px;
    }
  }
}
</style>