<template>
  <div class="nlp">
    <div class="toolbar">
      <el-form class="search-form" :inline="true" size="small">
        <el-form-item
          class="search-form-gutter"
          :label="$t('nlpAnalysis.businessType')"
        >
          <el-select
            style="width: 66px"
            v-model="listQuery.businessType"
            filterable
          >
            <el-option
              v-for="item in businessTypeList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="search-form-gutter"
          :label="$t('nlpAnalysis.startDate')"
        >
          <el-select
            style="width: 105px"
            v-model="startDate"
            @change="changeDate"
            filterable
            collapse-tags
          >
            <el-option
              v-for="item in dateList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="search-form-gutter"
          :label="$t('nlpAnalysis.endDate')"
        >
          <el-select
            style="width: 105px"
            v-model="endDate"
            @change="changeDate"
            filterable
            collapse-tags
          >
            <el-option
              v-for="item in dateList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="search-form-gutter"
          :label="$t('nlpAnalysis.count')"
        >
          <el-tag
            :type="listQuery.dates.length > 0 ? 'success' : 'danger'"
            class="date-count"
            >{{ listQuery.dates.length }}</el-tag
          >
        </el-form-item>
        <el-form-item
          class="search-form-gutter"
          :label="$t('nlpAnalysis.item')"
        >
          <el-select
            style="width: 221px"
            v-model="listQuery.items"
            :placeholder="$t('common.all')"
            filterable
            multiple
            collapse-tags
            clearable
          >
            <el-option
              v-for="item in itemList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="search-form-gutter"
          :label="$t('nlpAnalysis.length')"
        >
          <el-select
            style="width: 157px"
            v-model="listQuery.lengthTypes"
            :placeholder="$t('common.all')"
            filterable
            multiple
            collapse-tags
            clearable
          >
            <el-option
              v-for="item in lengthList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="search-form-gutter"
          :label="$t('nlpAnalysis.category')"
        >
          <el-cascader
            style="width: 342px"
            v-model="listQuery.categories"
            :options="categoriesList"
            :props="{ multiple: true, label: 'value' }"
            :placeholder="$t('common.all')"
            filterable
            collapse-tags
            clearable
          >
          </el-cascader>
        </el-form-item>

        <el-form-item class="search-form-gutter">
          <el-button
            :disabled="listQuery.dates.length === 0"
            size="small"
            :circle="true"
            type="primary"
            @click="fetchData"
            icon="el-icon-search"
          ></el-button>
        </el-form-item>
        <el-form-item class="search-form-gutter">
          <el-button
            :disabled="!listQuery.dates || listQuery.dates.length === 0"
            class="excel-dl-btn"
            size="small"
            :circle="true"
            @click="handleDownload"
            icon="el-icon-download"
          ></el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-row class="data-area" v-loading="dataLoading">
      <div id="trend-chart" />
    </el-row>
  </div>
</template>
  
<script>
import * as echarts from "echarts";
import { downloadFileAuth, serialize } from "@/utils/download-file";
import { aiApiRoot } from "@/utils/server";
import { getToken } from "@/utils/auth";
import {
  fetchBusinessTypes,
  fetchCategories,
  fetchDates,
  fetchWordFrequencyTrend,
} from "@/api/nlp";

export default {
  components: {},
  data() {
    return {
      startDate: "",
      endDate: "",
      listQuery: {
        businessType: "",
        dates: [],
        items: [],
        lengthTypes: [],
        categories: [],
      },
      tableData: [],
      businessTypeList: [],
      dateList: [],
      itemList: [],
      lengthList: ["1.1", "2.02~04", "3.05~07", "4.08~"],
      categoriesList: [],
      dataLoading: false,
      randomWords: [],
    };
  },
  mounted() {
    this.chart = echarts.init(document.getElementById("trend-chart"));
    window.addEventListener("resize", this.loadChart);
    this.fetchBusinessTypeList();
    this.fetchDates();
    this.fetchCategories();
    this.$store.dispatch("category/fresh").then(() => {
      this.itemList = this.$store.getters.t2AdditionalFlatten;
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.loadChart);
    this.unloadChart();
  },
  methods: {
    loadChart() {
      this.chart.resize();
    },
    unloadChart() {
      this.chart.dispose();
    },
    fetchBusinessTypeList() {
      fetchBusinessTypes().then((response) => {
        if (response.success) {
          this.businessTypeList = response.result.items || [];
          if (this.businessTypeList.length > 0) {
            this.listQuery.businessType = this.businessTypeList[0];
            this.fetchData();
          }
        }
      });
    },
    fetchCategories() {
      fetchCategories(this.source).then((response) => {
        if (response.success) {
          this.categoriesList = response.result;
        }
      });
    },
    fetchDates() {
      fetchDates(this.source).then((response) => {
        if (response.success) {
          this.dateList = response.result.items || [];
          if (this.dateList.length > 0) {
            this.endDate = this.dateList[0];
            // 3-month
            let startDate = this.dateList[0];
            let end = parseInt(this.dateList[0]);
            let endMonth =
              Math.floor(end / 100 / 100) * 12 + Math.floor((end / 100) % 100);
            let endDay = end % 100;
            for (let i = 0; i < this.dateList.length; i++) {
              let start = parseInt(this.dateList[i]);
              let startMonth =
                Math.floor(start / 100 / 100) * 12 +
                Math.floor((start / 100) % 100);
              let startDay = start % 100;
              if (
                endMonth - startMonth > 3 ||
                (endMonth - startMonth === 3 && endDay > startDay)
              ) {
                break;
              }
              startDate = this.dateList[i];
            }
            this.startDate = startDate;
            this.changeDate();
            this.fetchData();
          }
        }
      });
    },
    fetchData() {
      if (
        !this.listQuery.dates ||
        this.listQuery.dates.length === 0 ||
        !this.listQuery.businessType
      ) {
        return;
      }
      let morphemes = [];
      // 将categories拆解为Category3，不需要其他
      this.listQuery.categories.forEach((e) => {
        morphemes.push(e[e.length - 1]);
      });
      fetchWordFrequencyTrend(
        {
          dates: this.listQuery.dates.join(","),
          items: this.listQuery.items.join(","),
          lengthTypes: this.listQuery.lengthTypes.join(","),
          morphemes: morphemes.join(","),
          businessType: this.listQuery.businessType,
          limit: 50,
        },
        this.source
      ).then((response) => {
        if (response.success) {
          this.tableData = response.result;
          if (this.source === "SW") {
            this.tableData &&
              this.tableData.keywords &&
              this.tableData.keywords.forEach((e) => {
                e.nums &&
                  e.nums.forEach((_, i) => {
                    e.nums[i] *= 1888;
                  });
              });
          }
          this.drawChart();
        }
      });
    },
    handleDownload() {
      const loading = this.$loading({
        lock: true,
        text: this.$t("common.preparingData"),
      });
      let morphemes = [];
      // 将categories拆解为Category3，不需要其他
      this.listQuery.categories.forEach((e) => {
        morphemes.push(e[e.length - 1]);
      });
      let query = {
        dates: this.listQuery.dates.join(","),
        items: this.listQuery.items.join(","),
        lengthTypes: this.listQuery.lengthTypes.join(","),
        morphemes: morphemes.join(","),
        businessType: this.listQuery.businessType,
        limit: 50,
      };
      downloadFileAuth(
        aiApiRoot +
          "/v1/nlp-analysis/" +
          this.source +
          "/word-frequency-trend-download?" +
          serialize(query),
        query.dates + "_nlp_analysis_" + this.source + ".xlsx",
        getToken(),
        () => {
          loading.close();
        }
      );
    },
    drawChart() {
      let option = {
        title: {
          text: this.$t("nlpAnalysis.trend"),
          subtext: "Best 50",
          padding: [5, 10],
        },
        grid: {
          left: 20,
          bottom: 0,
          containLabel: true,
        },
        tooltip: {},
        legend: {
          type: "scroll",
          orient: "vertical",
          top: "center",
          right: 10,
          data: [],
          reversed: true,
          selector: [
            {
              type: "all",
              title: this.$t("common.selectAll"),
            },
            {
              type: "inverse",
              title: this.$t("common.selectReverse"),
            },
          ],
        },
        xAxis: {
          type: "category",
          axisLabel: {
            rotate: 45,
          },
          data: this.tableData.dates,
        },
        yAxis: {
          type: "value",
        },
        series: [],
      };
      this.tableData.keywords.forEach((e) => {
        option.series.push({
          name: e.name,
          type: "line",
          symbol: "circle",
          data: e.nums,
        });
        option.legend.data.push(e.name);
      });
      this.chart.setOption(option, true);
    },
    changeDate() {
      // 时间区间转离散值
      let dates = [];
      for (let i = 0; i < this.dateList.length; i++) {
        const e = this.dateList[i];
        if (e >= this.startDate && e <= this.endDate) {
          dates.push(e);
        }
      }
      this.listQuery.dates = dates;
    },
  },
  computed: {
    source() {
      if (!this.$route.meta) {
        return null;
      }
      return this.$route.meta["source"];
    },
  },
  watch: {
    startDate() {
      this.changeDate();
    },
    endDate() {
      this.changeDate();
    },
  },
};
</script>
  
<style lang="scss" scoped>
.nlp {
  .toolbar {
    background-color: #fff;
    padding: 10px 10px 0 10px;
    width: 100%;
    margin-bottom: 9.5px;

    .search-form {
      min-height: 32px;
      display: inline-block;

      .search-form-gutter {
        margin-bottom: 10px;
      }

      /deep/ .el-cascader .el-input__inner {
        height: 32px !important;
      }

      /deep/ .el-cascader__tags {
        padding-top: 2px;
      }

      .date-count {
        padding: 0 5px;
      }
    }
  }

  .data-area {
    background-color: #fff;
    margin-top: 5px;
  }

  #trend-chart {
    width: 100%;
    min-height: 248px;
    height: calc(100vh - 100px - 52.5px - 45px - 53px - 30px - 20px);
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
</style>